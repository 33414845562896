<template>
  <div class="flex justify-center flex-grow">
    <div class="max-w-4xl p-24">

      <p><strong>HIT</strong> supports a growing community of independent artists and friends. Find collaborators in your
        neighborhood and discover live shows happening nearby.</p>

      <div class="container mx-auto">
        <div class="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-2 gap-2">
          <div class="flex justify-center p-3">
            <a href="https://apps.apple.com/us/app/hit-live-performance/id1572294828?platform=iphone">
              <img width="136.3" height="46" src="../assets/store-apple.png">
            </a>
          </div>
          <div class="flex justify-center p-3">
            <a href="https://play.google.com/store/apps/details?id=com.duwu.app.main">
              <img width="153" height="46" src="../assets/store-googleplay.png">
            </a>
          </div>
        </div>
      </div>

      <!-- <div class="btn-container flex center-center">
        <a class="apply-btn btn" type="submit" href="/" >Apply For Membership</a>
    </div>  -->

    </div>
  </div>
</template>
