import { createRouter, createWebHistory } from "vue-router";
import HomePage from "@/views/HomePage.vue";
import PrivacyPolicy from "@/views/PrivacyPolicy.vue";
import TermsAndConditions from "@/views/TermsAndConditions.vue";
import ContactUs from "@/views/ContactUs.vue";
import StripeRedirector from "@/views/StripeRedirector.vue";
import SupportPage from "@/views/SupportPage.vue";

const routes = [
  {
    path: "/",
    name: "Home Page",
    component: HomePage,
  },
  {
    path: "/privacy-policy",
    name: "Privacy Policy",
    component: PrivacyPolicy,
  },
  {
    path: "/terms-and-conditions",
    name: "Terms And Conditions",
    component: TermsAndConditions,
  },
  {
    path: "/contact-us",
    name: "Contact Us",
    component: ContactUs,
  },
  {
    path: "/redirector",
    name: "Stripe Redirector",
    component: StripeRedirector,
  },
  {
    path: "/support",
    name: "Support",
    component: SupportPage,
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

export default router;
