<template>
<div>
  <div class="mx-auto max-w-7xl px-4 pt-12 sm:pt-24">
    <div class="text-center">
      <h1 class="headline text-4xl tracking-tight font-extrabold sm:text-5xl md:text-5xl">
        <span class="block">Success!</span>
      </h1>

      <p class="mt-3 max-w-md mx-auto text-2xl text-black md:mt-5 md:text-2xl md:max-w-4xl">
        Your account onboarding is complete, you are almost ready to get paid. Please click the button below to redirect to Hit app
      </p>
    

      <div class="button_cont" align="center">
        <a class="completeButton" @click="continueToApp">Return to HIT App</a>
      </div>


    </div>
  </div>
</div>
</template>


<script>
export default {
  methods: {
    continueToApp() {
      window.open(process.env.VUE_APP_REDIRECT_URL);
    }
  }
}

</script>

<style>
  .headline p {
    font-family: verdana;
    font-size: 48px;
    color: white;
    text-align: center;
  }


  .button_cont {
    padding: 100px;
    cursor: pointer;
  }

  .completeButton {
	color: #fff !important;
	text-decoration: none;
	background: #666666;
	padding: 20px;
	border-radius: 5px;
	display: inline-block;
	border: none;
	transition: all 0.4s ease 0s;
}

.completeButton:hover {
	background: #3a3a3a;
	-webkit-box-shadow: 0px 5px 40px -10px rgba(0,0,0,0.57);
	-moz-box-shadow: 0px 5px 40px -10px rgba(0,0,0,0.57);
	box-shadow: 5px 40px -10px rgba(0,0,0,0.57);
	transition: all 0.4s ease 0s;
}
</style>