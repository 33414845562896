import { createApp } from 'vue'
import App from './App.vue'
import './styles/app.css'
import router from './router'
import Vue from 'vue'

createApp(App).use(router).mount('#app')

new Vue({
  el: "#app",
  render: h => h(App)
});
