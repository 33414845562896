<template>
    <div class="support-page">
      <h1>Support</h1>
      <form @submit.prevent="submitForm" class="support-form">
        <div class="form-group">
          <label for="username">Username/Email:</label>
          <input type="text" v-model="form.username" required class="form-control" />
        </div>
        <div class="form-group">
          <label for="description">Description:</label>
          <textarea v-model="form.description" required class="form-control"></textarea>
        </div>
        <div class="form-group">
          <label for="reason">Reason:</label>
          <select v-model="form.reason" required class="form-control">
            <option value="">Select</option>
            <option value="subscribe">Subscribe to Newsletter</option>
            <option value="delete">Delete Account</option>
            <option value="enquiry">Enquiry</option>
            <option value="unsubscribe">Unsubscribe</option>
          </select>
        </div>
        <div class="form-group">
          <input type="checkbox" v-model="form.subscribe" id="subscribe" />
          <label for="subscribe">Subscribe to Newsletter</label>
        </div>
        <div class="form-group">
          <vue-recaptcha
            sitekey="6LfL4BwqAAAAACge00ZESxhj7ZanXS_pACRQxKnN"
            @verify="onVerify"
            @expired="onExpired"
          ></vue-recaptcha>
        </div>
        <button type="submit" :disabled="!recaptchaVerified" class="btn btn-primary">Submit</button>
      </form>
    </div>
  </template>
  
  <script>
  import axios from 'axios';
  import { VueRecaptcha } from 'vue-recaptcha';
  
  export default {
    components: {
      VueRecaptcha,
    },
    data() {
      return {
        form: {
          username: '',
          description: '',
          reason: '',
          subscribe: false,
        },
        recaptchaVerified: false,
        recaptchaToken: '',
      };
    },
    methods: {
      onVerify(recaptchaToken) {
        this.recaptchaVerified = true;
        this.recaptchaToken = recaptchaToken;
      },
      onExpired() {
        this.recaptchaVerified = false;
        this.recaptchaToken = '';
      },
      async submitForm() {
        try {
          const response = await axios.post(
            'https://us-central1-duwu-app-sit.cloudfunctions.net/sendEmail',
            {
              ...this.form,
              recaptchaToken: this.recaptchaToken,
            }
          );
          alert('Form submitted successfully!');
          console.log(response);
        } catch (error) {
          console.error('Error submitting form:', error);
          alert('Error submitting form');
        }
      },
    },
  };
  </script>
  
  <style scoped>
  .support-page {
    max-width: 600px;
    margin: 0 auto;
    padding: 20px;
    border: 1px solid #ddd;
    border-radius: 8px;
    background-color: #f9f9f9;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    .btn-primary:disabled {
      background-color: #cccccc;
    }
    .btn-primary {
      background-color: #333;
    }
  }
  
  .support-page h1 {
    text-align: center;
    margin-bottom: 20px;
    color: #333;
  }
  
  .support-form {
    display: flex;
    flex-direction: column;
  }
  
  .form-group {
    margin-bottom: 15px;
  }
  
  .form-group label {
    display: block;
    margin-bottom: 5px;
    font-weight: bold;
  }
  
  .form-control {
    width: 100%;
    padding: 8px;
    box-sizing: border-box;
    border: 1px solid #ccc;
    border-radius: 4px;
  }
  
  textarea.form-control {
    height: 100px;
  }
  
  .btn {
    display: inline-block;
    padding: 10px 20px;
    font-size: 16px;
    font-weight: bold;
    text-align: center;
    color: #fff;
    background-color: #007bff;
    border: none;
    border-radius: 4px;
    cursor: pointer;
  }
  
  .btn-primary {
    background-color: #007bff;
  }
  
  .btn-primary:disabled {
    background-color: #cccccc;
    cursor: not-allowed;
  }
  </style>
  