<template>
    <div>
        <ul class="navigation-container">
            <li class="navigation-items"><router-link :to="{ name: 'Home Page' }">Home</router-link></li>    
            <!-- <li class="navigation-items"><router-link :to="{ name: 'Contact Us' }">Contact</router-link></li>   -->
            <li class="navigation-items"><router-link :to="{ name: 'Privacy Policy' }">Privacy Policy</router-link></li>
            <li class="navigation-items"><router-link :to="{ name: 'Terms And Conditions' }">Terms and Conditions</router-link></li>
            <li class="navigation-items"><router-link :to="{ name: 'Support' }">Support</router-link></li>
        </ul>
    </div>
</template>