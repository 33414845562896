<template>
    <div id="app" class="navigation-header">
        <div class="logo-container">
            <a href="/"><img class="logo-img" src="./assets/hit-logo-white.png" width="100"></a>
        </div>
        <router-view />
    </div>
    <web-header></web-header>
    <web-footer></web-footer>
</template>

<script>
import WebHeader from './views/WebHeader.vue'
import WebFooter from './views/WebFooter.vue'

export default {
    name: 'App',
    components: {
        'web-header': WebHeader,
        'web-footer': WebFooter,
    },
}

</script>

